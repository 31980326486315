<template>
  <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
    <b-table
        sticky-header="calc(100vh - 13rem)"
        bordered
        responsive
        hover
        no-border-collapse
        :items="users"
        :fields="headers"
        class="stickyTable border-bottom"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-direction="desc"
    >
      <template #cell(phone)="{ item }">
        <div>{{ item.phone }}</div>
      </template>

      <template #cell(Divize)="{ item }">
        <div>{{ item.Divize }}</div>
      </template>

      <template #cell(joinedName)="{ item }">
        <div class="text-left">{{ item.joinedName }}</div>
      </template>

      <template #cell(position)="{ item }">
        <div>{{ item.position }}</div>
      </template>

      <template #cell(email)="{ item }">
        <div v-html="getEmailLink(item.email)"></div>
      </template>

      <template #cell(note)="{ item }">
        <div>{{ item.note }}</div>
      </template>

    </b-table>
    <div v-if="!users.length" class="pt-5">
      <h5 class="p-5 fs-23px">Žádné výsledky vyhledávání</h5>
    </div>
  </b-overlay>
</template>

<script>

import Axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";

export default {
  props: {

  },
  mixins: [commonMixin],
  components: {},
  computed: {},
  data() {
    return {
      isLoading: false,
      currentTab: 'seznamKontaktu',
      users: [],
      headers: [
        { label: 'Jméno, Příjmení (příp. titul)', key: 'joinedName', variant: 'color-1', sortable: true },
        { label: 'Divize', key: 'divize', variant: 'color-1', sortable: true },
        { label: 'Pracovní pozice', key: 'position', variant: 'color-1', sortable: true },
        { label: 'Telefonní číslo', key: 'phone', variant: 'color-1', sortable: true },
        { label: 'Email', key: 'email', variant: 'color-1', sortable: true },
        { label: 'Poznámka', key: 'note', variant: 'color-1', sortable: true },
      ],
      sortDesc: false,
    };
  },
  methods: {
    async getUsers() {
      try {
        const url = `/knihovna/getSenzamKontaktu`;
        const res = await Axios.get(url, {});
        const {data} = res;
        this.users = data.data.users;
      } catch (e) {
        console.log(e)
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getUsers();
    this.isLoading = false;
  },
};

</script>

<style>

</style>
