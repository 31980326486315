<template>
  <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
    <div class="d-flex w-100 justify-content-center pt-0 pb-4">
      <div class="w-50 text-left fs-15px mr-3">
        <template>
          <div class="sectionName pt-0 mb-0">Návod k CRM Trienergo</div>
          <responsible-user
              :category="category.NAVOD"
              :categoryUser="usersKnihovna.navod"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.NAVOD"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.navod_k_CRM" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('navod_k_CRM', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Obhlídkové formuláře</div>
          <responsible-user
              :category="category.OBHLIDKOVE_FORMULARE"
              :categoryUser="usersKnihovna.obhlidkoveFormulare"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.OBHLIDKOVE_FORMULARE"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.obhlidkove_formulare" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('obhlidkove_formulare', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Cenové nabídky (CN)</div>
          <responsible-user
              :category="category.CENOVE_NABIDKY"
              :categoryUser="usersKnihovna.cenoveNabidky"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.CENOVE_NABIDKY"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.cenove_nabidky" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('cenove_nabidky', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Dotace</div>
          <responsible-user
              :category="category.DOTACE"
              :categoryUser="usersKnihovna.dotace"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.DOTACE"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.dotace" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('dotace', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Administrativa</div>
          <responsible-user
              :category="category.ADMINISTRATIVA"
              :categoryUser="usersKnihovna.administrativa"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.ADMINISTRATIVA"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.administrativa" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('administrativa', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Vzory dokumentů</div>
          <responsible-user
              :category="category.VZORY_DOKUMENTU"
              :categoryUser="usersKnihovna.vzoryDokumentu"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.VZORY_DOKUMENTU"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.vzory_dokumentu" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('vzory_dokumentu', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
      </div>

      <div class="w-50 text-left fs-15px ml-3">
        <template>
          <div class="sectionName pt-0 mb-0">Panely, <span class="font-weight-normal">které běžně používáme</span></div>
          <responsible-user
              :category="category.PANELY"
              :categoryUser="usersKnihovna.panely"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.PANELY"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.panely_pouzivame" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('panely_pouzivame', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Střídače, <span class="font-weight-normal">které běžně používáme</span></div>
          <responsible-user
              :category="category.STRIDACE"
              :categoryUser="usersKnihovna.stridace"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.STRIDACE"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.stridace_pouzivame" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('stridace_pouzivame', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Baterie, <span class="font-weight-normal">které běžně používáme</span></div>
          <responsible-user
              :category="category.BATERIE"
              :categoryUser="usersKnihovna.baterie"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.BATERIE"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.baterie_pouzivame" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('baterie_pouzivame', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Příslušenství</div>
          <responsible-user
              :category="category.PRISLUSENSTVI"
              :categoryUser="usersKnihovna.prislusenstvi"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.PRISLUSENSTVI"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.prislusenstvi" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('prislusenstvi', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Fotovoltaický ohřev vody <span class="font-weight-normal">(NZÚ Light)</span></div>
          <responsible-user
              :category="category.OHREV_VODY"
              :categoryUser="usersKnihovna.ohrevVody"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.OHREV_VODY"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.ohrev_vody" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('ohrev_vody', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Prezentace ze školení</div>
          <responsible-user
              :category="category.PREZENTACE_ZE_SKOLENI"
              :categoryUser="usersKnihovna.prezentaceZeSkoleni"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.PREZENTACE_ZE_SKOLENI"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.prezentace_skoleni" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('prezentace_skoleni', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import _ from "lodash";
import ResponsibleUser from "@/components/knihovna/ResponsibleUser.vue";
import saveAs from "save-as";
import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";
import { MIME_TYPES } from "@/constants/form.constants.js";

const knihovnaFiles = {
  navod_k_CRM: [
    {
      fileName: 'CRM Trienergo. Kapitola 1. Nový obchodní případ (verze 14.03.2024)',
      fileServerName: 'CRM_Trienergo_Kapitola_1_Novy_obchodni_pripad',
      ext: 'pdf',
    },
    {
      fileName: 'CRM Trienergo. Kapitola 2. Základní údaje k novému obchodnímu případu (verze 01.03.2024)',
      fileServerName: 'CRM_Trienergo_Kapitola_2',
      ext: 'pdf',
    },
    {
      fileName: 'CRM Trienergo. Kapitola 3.1. Podklady pro případy typu „rodinný dům (RD)“ (verze 01.03.2024)',
      fileServerName: 'CRM_Trienergo_Kapitola_3_1',
      ext: 'pdf',
    },
    {
      fileName: 'Vzorová složka dokumentace obchodního případu (12.3.2024)',
      fileServerName: 'Vzorova_slozka_dokumentace_obchodniho_pripadu',
      ext: 'zip',
    },
    {
      fileName: 'Pravidlo číslování fotek (12.3.2024)',
      fileServerName: 'Pravidlo_cislovani_fotek',
      ext: 'pdf',
    },
  ],
  cenove_nabidky: [
    {
      fileName: 'CN Trienergo – rodinné a bytové domy – cenová nabídka (verze 10.75)',
      fileServerName: 'CN_Trienergo_rodinne_a_bytove_domy_cenova_nabidka',
      ext: 'xlsm',
    },
    {
      fileName: 'CN Trienergo – rodinné domy – cenová nabídka na instalaci FV ohřevu TUV (verze 4.7.2024)',
      fileServerName: 'CN_Trienergo_rodinne_domy_cenova_nabidka_na_instalaci_FV_ohrevu_TUV',
      ext: 'xlsm',
    },
    {
      fileName: 'CN TrienergoSolar – firmy – cenová nabídka indikativní (verze 6.1)',
      fileServerName: 'CN_TrienergoSolar_firmy_cenova_nabidka_indikativni',
      ext: 'xlsm',
    },
    {
      fileName: 'CN TrienergoSolar – firmy – s podporou MŽP podle výzvy RES+ 1_2024 (verze 4.0)',
      fileServerName: 'CN_TrienergoSolar_firmy_s_podporou_MZP_podle_vyzvy_RES',
      ext: 'xlsm',
    },
    {
      fileName: 'Emaily, vzor (verze 30.04.2024)',
      fileServerName: 'Emaily_vzor',
      ext: 'zip',
    },
  ],
  dotace: [
    {
      fileName: 'Nová zelená úsporám - přehled programů (verze 31.1.2024)',
      fileServerName: 'Nova_zelena_usporam_prehled_programu',
      ext: 'pdf',
    }, {
      fileName: 'Nová zelená úsporám - RODINNÉ DOMY - závazné pokyny (verze 1.6.2022)',
      fileServerName: 'Nova_zelena_usporam_RODINNE_DOMY_zavazne_pokyny_2022',
      ext: 'pdf',
    }, {
      fileName: 'Nová zelená úsporám - RODINNÉ DOMY - závazné pokyny (verze 1.9.2023)',
      fileServerName: 'Nova_zelena_usporam_RODINNE_DOMY_zavazne_pokyny_2023',
      ext: 'pdf',
    }, {
      fileName: 'Nová zelená úsporám - RODINNÉ DOMY - závazné pokyny (verze 31.1.2024)',
      fileServerName: 'Nova_zelena_usporam_RODINNE_DOMY_zavazne_pokyny',
      ext: 'pdf',
    }, {
      fileName: 'Nová zelená úsporám - Seznam dokládaných dokumentů při podání žádosti (verze 31.1.2024)',
      fileServerName: 'Nova_zelena_usporam_Seznam_dokladanych_dokumentu_pri_podani_zadosti',
      ext: 'pdf',
    }, {
      fileName: 'Nová zelená úsporám - Seznam dokládaných dokumentů při doložení realizace (verze 31.1.2024)',
      fileServerName: 'Nova_zelena_usporam_Seznam_dokladanych_dokumentu_pri_dolozeni_realizace',
      ext: 'pdf',
    }, {
      fileName: 'Výzva RES+ č. 1_2024 – Instalace nových FVE od 10 kWp do 5 MWp s vlastní spotřebou',
      fileServerName: 'Vyzva_RES_c_1_2024_Instalace_novych_FVE_10_kW_5_MW_s_vlastni_spotrebou_pdf',
      ext: 'pdf',
    }, {
      fileName: 'Výzva RES+ č. 1_2024 – Stanovení maximální dotace pro FVE od 5 kWp do 5 MWp',
      fileServerName: 'Vyzva_RES_c_1_2024_Stanoveni_maximalni_dotace_pro_FVE_od_5_kWp_do_5_MWp',
      ext: 'xlsx',
    }, {
      fileName: 'Výzva RES+ č. 3_2024 – Instalace nových FVE - obce s maximálním počtem obyvatel 3000',
      fileServerName: 'Vyzva_RES_c_3_2024_Instalace_novych_FVE_obce_s_maximalnim_poctem_obyvatel_3000_pdf',
      ext: 'pdf',
    }, {
      fileName: 'Výzva RES+ č. 3_2024 – Stanovení maximální výše podpory na FVE pro obce do 3000 obyvatel',
      fileServerName: 'Vyzva_RES_c_3_2024_Stanoveni_maximalni_vyse_podpory_na_FVE_pro_obce_do_3000_obyvatel',
      ext: 'xlsx',
    }, {
      fileName: 'Výzva RES+ č. 4_2024 – Instalace nových FVE - kraje, obce, městské obvody a části, církve',
      fileServerName: 'Vyzva_RES_c_4_2024_Instalace_novych_FVE_kraje_obce_mestske_obvody_a_casti_cirkve',
      ext: 'pdf',
    }, {
      fileName: 'Prezentace - Dotace 2024. Modernizační fond SFŽP RES+ (verze 19.2.2024)',
      fileServerName: 'Prezentace_Dotace_2024_Modernizacni_fond_SFZP_RES',
      ext: 'pdf',
    },
    {
      fileName: 'Prezentace - Dotace a výzvy. Nová zelená úsporám (verze 19.2.2024)',
      fileServerName: 'Prezentace_Dotace_a_vyzvy_Nova_zelena_usporam',
      ext: 'pdf',
    },
    {
      fileName: 'Ceník služeb – Program Modernizační fond RES+ 2024 (verze 5.3.2024)',
      fileServerName: 'Cenik_sluzeb_Program_Modernizacni_fond_RES',
      ext: 'pdf',
    },
  ],
  vzory_dokumentu: [
    {
      fileName: 'Vzor - Smlouva o připojení (SoP) ČEZ - firma, FVE do 10 kWp',
      fileServerName: 'Vzor_Smlouva_o_pripojeni_SoP_CEZ_firma_FVE_do_10_kWp',
      ext: 'pdf',
    }, {
      fileName: 'Vzor - Smlouva o připojení (SoP) EG.D - rodinný dům, FVE do 10 kWp',
      fileServerName: 'Vzor_Smlouva_o_pripojeni_SoP_EGD_rodinny_dum_FVE_do_10_kWp',
      ext: 'pdf',
    },
  ],
  obhlidkove_formulare: [
    {
      fileName: 'Obhlídkový formulář pro Rodinné domy, RD (verze 14.03.2024)',
      fileServerName: 'Obhlidkovy_formular_pro_Rodinne_domy',
      ext: 'xlsx',
    },
    {
      fileName: 'Obhlídkový formulář pro RD – vzor č.1 správného vyplnění (verze 20.02.2024)',
      fileServerName: 'Obhlidkovy_formular_pro_RD_vzor_c_1_spravneho_vyplneni_verze_20',
      ext: 'pdf',
    },
    {
      fileName: 'Obhlídkový formulář pro RD – vzor č.2 správného vyplnění (verze 20.02.2024)',
      fileServerName: 'Obhlidkovy_formular_pro_RD_vzor_c_2_spravneho_vyplneni_verze_20',
      ext: 'pdf',
    },

    {
      fileName: 'Obhlídkový formulář pro Firmy (verze 13.03.2024)',
      fileServerName: 'Obhlidkovy_formular_pro_Firmy',
      ext: 'xlsm',
    },
  ],
  panely_pouzivame: [
    {
      fileName: 'WINAICO – panels WST-425NGXB a 430NGX Glass-Glass Series – datasheet',
      fileServerName: 'WINAICO_panels_WST_425NGXB_a_430NGX_Glass_Glass_Series_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'WINAICO – panels WST-530NGX-D3 Glass-Glass Series – datasheet',
      fileServerName: 'WINAICO_panels_WST_530NGX_D3_Glass_Glass_Series_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'CanadianSolar – panels HiKu6 Mono PERC CS6L-445_465MS – datasheet',
      fileServerName: 'CanadianSolar_panels_HiKu6_Mono_PERC_CS6L_445_465MS_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'RUNERGY – panels HY-DH108N8B 430 Wp – datasheet',
      fileServerName: 'RUNERGY_panels_HY_DH108N8B_430_Wp_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'RUNERGY – panels HY-DH120N8 465_485 Wp – datasheet',
      fileServerName: 'RUNERGY_panels_HY_DH120N8_465_485_Wp_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'ULICA SOLAR – panels UL-450M-455M-460M-144HV – datasheet',
      fileServerName: 'ULICA_SOLAR_panels_UL_450M_455M_460M_144HV_datasheet',
      ext: 'pdf',
    },
  ],
  stridace_pouzivame: [
    {
      fileName: 'Huawei SUN2000-30_36_40KTL-M3 – Smart PV Controller – datasheet',
      fileServerName: 'Huawei_SUN2000_30_36_40KTL_M3_Smart_PV_Controller_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'Huawei SUN2000-100KTL-M2 – Inteligentní FV regulátor – datasheet',
      fileServerName: 'Huawei_SUN2000_100KTL_M2_Inteligentni_FV_regulator_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'WATTSONIC – Three Phase 6.0_20K-25A Hybrid AIO ESS – datasheet',
      fileServerName: 'WATTSONIC_Three_Phase_6_0_20K_25A_Hybrid_AIO_ESS_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'WATSONIC – Návod k obsluze střídačů Wattsonic Gen3 od 4KW-25A-3P do 20KW-40A-3P',
      fileServerName: 'WATSONIC_Navod_k_obsluze_stridacu_Wattsonic_Gen3_od_4KW_25A_3P_do_20KW_40A_3P',
      ext: 'pdf',
    },
    {
      fileName: 'WATSONIC – Návod pro nastavení provozních parametrů střídače v aplikaci Wattsonic Cloud',
      fileServerName: 'WATSONIC_Navod_pro_nastaveni_provoznich_parametru_stridace_v_aplikaci_Wattsonic_Cloud',
      ext: 'pdf',
    },
    {
      fileName: 'WATSONIC – Návod pro připojení střídače k WIFI (GEN2 verze s anténou bez QR kódu)',
      fileServerName: 'WATSONIC_Navod_pro_pripojeni_stridace_k_WIFI_GEN2_verze_s_antenou_bez_QR_kodu',
      ext: 'pdf',
    },
    {
      fileName: 'WATSONIC – Návod pro připojení střídače k WIFI (GEN3 verze s anténou s QR kódem)',
      fileServerName: 'WATSONIC_Navod_pro_pripojeni_stridace_k_WIFI_GEN3_verze_s_antenou_s_QR_kodem',
      ext: 'pdf',
    },
    {
      fileName: 'SMA – Sunny Tripower CORE 2 STP 110-60 – datasheet',
      fileServerName: 'SMA_Sunny_Tripower_CORE_2_STP_110_60_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'GOODWE – MT-serise Inverter, 50-80 kW – datasheet',
      fileServerName: 'GOODWE_MT_serise_Inverter_50_80_kW_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'GOODWE – Inverter GW20KT-DT – datasheet',
      fileServerName: 'GOODWE_Inverter_GW20KT_DT_datasheet',
      ext: 'pdf',
    },
  ],
  baterie_pouzivame: [
    {
      fileName: 'Huawei LUNA2000-5_10_15-S0 – Bateriový systém (BMS, baterie 5 kWh, 10 kWh, 15 kWh) – datasheet',
      fileServerName: 'Huawei_LUNA2000_5_10_15_S0_Bateriovy_system_BMS_baterie_5_kWh_10_kWh_15_kWh_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'WATTSONIC – Bateriový systém (BMS, baterie 2,3 kWh a 3,84 kWh) – datasheet',
      fileServerName: 'WATTSONIC_Bateriovy_system_BMS_baterie_2_3_kWh_a_3_84_kWh_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'DYNESS – Energy Storage System Dyness Tower T7_T21 – datasheet',
      fileServerName: 'DYNESS_Energy_Storage_System_Dyness_Tower_T7_T21_datasheet',
      ext: 'pdf',
    },
  ],
  prislusenstvi: [
    {
      fileName: 'TESS - Trienergo Smart Systém by SH&E (verze 16.5.2024)',
      fileServerName: 'TESS_Trienergo_Smart_System_by_SH_E',
      ext: 'pdf',
    },
    {
      fileName: 'OlifeEnergy WallBox – stanice pro nabíjení elektromobilů – datasheet',
      fileServerName: 'OlifeEnergy_WallBox_stanice_pro_nabijeni_elektromobilu_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'WATTrouter ECO - uživatelská příručka, návod k instalaci a nastavení přístroje (verze 20.3.2020)',
      fileServerName: 'WATTrouter_ECO_uzivatelska_prirucka_navod_k_instalaci_a_nastaveni_pristroje',
      ext: 'pdf',
    },
    {
      fileName: 'Tigo TS4-A-O – optimalizace, monitorování a rychlé vypnutí – datasheet',
      fileServerName: 'Tigo_TS4_A_O_optimalizace_monitorovani_a_rychle_vypnuti_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'Tigo CCA – kompaktní, výkonná datová a komunikační brána – datasheet',
      fileServerName: 'Tigo_CCA_kompaktni_vykonna_datova_a_komunikacni_brana_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'Tigo TAP – přístupový bod, bezdrátové síťové komunikační zařízení – datasheet',
      fileServerName: 'Tigo_TAP_pristupovy_bod_bezdraztove_sitove_komunikacni_zarizeni_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'Huawei SUN2000-600W-P – optimizer, smart module controller – datasheet',
      fileServerName: 'Huawei_SUN2000_600W_P_optimizer_smart_module_controller_datasheet',
      ext: 'pdf',
    },
    {
      fileName: 'Huawei MERC-1100_1300W-P – optimizer, smart module controller – datasheet',
      fileServerName: 'Huawei_MERC_1100_1300W_P_optimizer_smart_module_controller_datasheet',
      ext: 'pdf',
    },
  ],
  ohrev_vody: [
    {
      fileName: 'LOGITEX – Přímé využití energie z fotovoltaických panelů – katalog',
      fileServerName: 'LOGITEX_Prime_vyuziti_energie_z_fotovoltaickych_panelu_katalog',
      ext: 'pdf',
    }, {
      fileName: 'LOGITEX – schémata 2,3 kW',
      fileServerName: 'LOGITEX_schemata_2_3_kW',
      ext: 'pdf',
    }, {
      fileName: 'LOGITEX – LXDC BOX – návod na obsluhu a instalaci',
      fileServerName: 'LOGITEX_LXDC_BOX_navod_na_obsluhu_a_instalaci',
      ext: 'pdf',
    }, {
      fileName: 'LOGITEX – LXDC POWER BOX – návod na obsluhu a instalaci',
      fileServerName: 'LOGITEX_LXDC_POWER_BOX_navod_na_obsluhu_a_instalaci',
      ext: 'pdf',
    }, {
      fileName: 'ULICA SOLAR – panels UL-450M-455M-460M-144HV – datasheet',
      fileServerName: 'ULICA_SOLAR_panels_UL_450M_455M_460M_144HV_datasheet',
      ext: 'pdf',
    },
  ],
  prezentace_skoleni: [
    {
      fileName: '2024-03-14 – Fotovoltaické systémy – basic (Kroupa)',
      fileServerName: 'Fotovoltaicke_systemy_basic_Kroupa',
      ext: 'pdf',
    }, {
      fileName: '2024-03-14 – Fotovoltaické systémy – advanced (Kroupa)',
      fileServerName: 'Fotovoltaicke_systemy_advanced_Kroupa',
      ext: 'pdf',
    }, {
      fileName: '2024-04-25 – Tri energo Solar – kompetence, procesy, aliasy (Bílková, Kroupa)',
      fileServerName: 'Tri_energo_Solar_kompetence_procesy_aliasy_Bilkova_Kroupa',
      ext: 'pdf',
    }, {
      fileName: '2024-03-14 – Tri energo Solar – zákazníci B2B a sdílení (Kroupa)',
      fileServerName: 'Tri_energo_Solar_zakaznici_B2B_a_sdileni_Kroupa',
      ext: 'pdf',
    },
  ],
  administrativa: [
    {
      fileName: 'AIS kód - Generování přes Bank ID (verze 20.02.2024)',
      fileServerName: 'AIS_kod_Generovani_pres_Bank_ID',
      ext: 'pdf',
    }, {
      fileName: 'AIS kód - Postup pro aktivaci eidentity - Bankovní identita (verze 20.02.2024)',
      fileServerName: 'AIS_kod_Postup_pro_aktivaci_eidentity_Bankovni_identita',
      ext: 'pdf',
    }, {
      fileName: 'AIS kód - Postup pro aktivaci eidentity - Czech Point (verze 20.02.2024)',
      fileServerName: 'AIS_kod_Postup_pro_aktivaci_eidentity_Czech_Point',
      ext: 'pdf',
    }, {
      fileName: 'AIS kód - Návod SFZP - Přidání zprostředkovatele (verze 20.02.2024)',
      fileServerName: 'AIS_kod_Navod_SFZP_Pridani_zprostredkovatele',
      ext: 'pdf',
    },
  ],
}

const CATEGORY = {
  NAVOD: 'navod',
  OBHLIDKOVE_FORMULARE: 'obhlidkoveFormulare',
  CENOVE_NABIDKY: 'cenoveNabidky',
  DOTACE: 'dotace',
  VZORY_DOKUMENTU: 'vzoryDokumentu',
  PANELY: 'panely',
  STRIDACE: 'stridace',
  BATERIE: 'baterie',
  OHREV_VODY: 'ohrevVody',
  PREZENTACE_ZE_SKOLENI: 'prezentaceZeSkoleni',
  ADMINISTRATIVA: 'administrativa',
  PRISLUSENSTVI: 'prislusenstvi',
}

export default {
  components: {ResponsibleUser},
  mixins: [commonMixin],
  data() {
    return {
      category: CATEGORY,
      isLoading: false,
      files: knihovnaFiles,
      usersKnihovna: {
        navod: null,
        obhlidkoveFormulare: null,
        cenoveNabidky: null,
        dotace: null,
        vzoryDokumentu: null,
        panely: null,
        stridace: null,
        baterie: null,
        ohrevVody: null,
        prezentaceZeSkoleni: null,
        administrativa: null,
        prislusenstvi: null,
      },
      editCategory: null,
    };
  },
  computed: {  },
  methods: {
    async downloadFile(blockFiles, fileServerName) {
      const fileInfo = _.get(this.files, blockFiles).find(file => file.fileServerName === fileServerName);
      if (!fileInfo) {
        return this.$snotify['error']('Soubor nenalezen');
      }

      return this.downloadFileFromServer(fileInfo);
    },
    async downloadFileFromServer({fileServerName, fileName, ext}) {
      try {
        const url = `/file/knihovna/download`;
        const {data} = await axios.post(url, {fileName: fileServerName, ext}, {responseType: "blob"});
        if (!Object.values(MIME_TYPES).includes(data?.type)) {
          return this.$snotify['error']('Problém se stahováním souboru');
        }
        const fileNameWithExt = `${fileName}.${ext}`;
        saveAs(data, fileNameWithExt);
      } catch (e) {
        console.log('downloadFileFromServer', e)
      }
    },
    async changeUser() {
      this.editCategory = null;
      await this.getResponsibleUsers();
    },
    async getResponsibleUsers() {
      try {
        const url = `/knihovna/getResponsibleUsers`;
        const {data: {data}} = await axios.get(url) || {};
        this.usersKnihovna = {...this.usersKnihovna, ...data} || {};
      } catch (e) {
        console.log('ResponsibleUsers', e)
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getResponsibleUsers();
    this.isLoading = false;
  },
};
</script>

<style scoped>
.i-vert-02 {
  vertical-align: -0.2em;
}
.sectionName {
  font-size: 18px;
  margin-bottom: 5px;
  padding-top: 1.5rem;
  font-weight: bold;
  text-align: left;
  padding-left: 20px;
}
p {
  margin-bottom: 4px;
}
</style>
