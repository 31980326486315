<template>
  <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
    <div class="d-flex w-100 justify-content-center pt-0 pb-4 pt-5">
      <div class="w-50 text-left fs-15px mr-3">
        <template>
          <b-card v-for="file in templates" :key="file.fileServerName" class="mb-2">
            <file-page
              :fileInfo="file"
              @updateFiles="getFilesFromServer"
            />
          </b-card>
        </template>
      </div>
    </div>
  </b-overlay>
</template>

<script>

import _ from "lodash";
import axios from "axios";
import { FILE_TYPES } from "@/constants/form.constants";
import commonMixin from "@/mixins/common.mixin.ts";
import FilePage from "@/components/knihovna/FilePage";

export default {
  name: "SablonyDatatabulkyTab",
  mixins: [commonMixin],
  components: {FilePage},
  data() {
    return {
      isLoading: false,
      templates: [],
      abortController: new AbortController(),
      abortControllerCount: new AbortController(),
      fileType: FILE_TYPES.templates,
    }
  },
  methods: {
    async getFilesFromServer() {
      try {
        this.abortControllerCount.abort();
        this.abortControllerCount = new AbortController();

        const url = `/file/getFilesFromServer`;
        const {data: {data}} = await axios.post(url, {fileType: this.fileType},
          {signal: this.abortController.signal}
        );
        this.templates = _.sortBy(data.result, ['order'])
      } catch (e) {
        console.log('getFilesFromServer', e)
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getFilesFromServer()
    this.isLoading = false;
  },
}
</script>

<style scoped>

</style>