<template>
  <div>
    <div class="mb-1">
      <template>
        <b-icon-pencil
          v-if="!showEditBlock"
          variant="primary"
          class="mr-3 cursor-pointer"
          title="Upravit šablonu"
          @click="showEdit"
        />
        <input type="file" ref="fileInput" class="display-none" @change="handleFileChange" :accept="'.'+fileInfo.ext" />
      </template>
      <a href="#"
         @click="downloadFile(fileInfo)"
         title="Stáhnout soubor"
      >
        <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
        {{ fileInfo.fileName }}
      </a>
    </div>
    <template v-if="showEditBlock" class="mt-2">
      <a v-if="!uploadedFile.name" href="#" @click="editTemplate" class="mr-2 custom-link-black link-no-underlined">
        <b-icon-cloud-upload
          variant="primary"
          class="ml-4 mr-2 pt-1 cursor-pointer"
          font-scale="1.5"
          title="Nahrát soubor"
        />
        vyberte soubor
      </a>
      <template v-if="uploadedFile.name">
        <span class="ml-4 color-grey">Vybraný souborr: {{ uploadedFile.name }} </span>
        <b-button variant="outline-primary" class="p-1 ml-3 fs-13px mw-50px" @click="agreeReplaceFileModal"> Uložit </b-button>
      </template>
      <b-button variant="outline-danger" class="p-1 ml-1 fs-13px mw-50px" @click="discardUpload"> Zrušit </b-button>
    </template>

    <b-modal :id="'agree-replace-file-modal' + fileInfo.serverFileName" centered hide-footer :title="'Smazat soubor ' + fileInfo.fileName" dialog-class="main-font">
      <p class="my-3 bold">Původní soubor bude nenávratně smazán. Chcete provést smazání?</p>
      <div class="d-flex justify-content-end pt-3">
        <b-button class="mr-3" variant="primary" @click="discardUpload">Zrušit</b-button>
        <b-button variant="outline-danger" @click="uploadFile">Ano</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import {MIME_TYPES} from "@/constants/form.constants";
import saveAs from "save-as";

export default {
  name: "FilePage",
  props: {
    fileInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      uploadedFile: {},
      showEditBlock: false,
    };
  },
  methods: {
    showEdit() {
      this.showEditBlock = true;
    },
    editTemplate() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.uploadedFile = event.target.files[0];
    },
    async downloadFile(fileInfo) {
      try {
        this.isLoading = true;

        if (!fileInfo) {
          return this.$snotify['error']('Soubor nenalezen');
        }
        const url = `/file/downloadFiles`;
        const {data} = await axios.post(url, { ...fileInfo }, {responseType: "blob"});
        if (!Object.values(MIME_TYPES).includes(data?.type)) {
          return this.$snotify['error']('Problém se stahováním souboru');
        }
        const fileNameWithExt = `${fileInfo.serverFileName}.${fileInfo.ext}`;
        saveAs(data, fileNameWithExt);

        this.isLoading = false;
      } catch (e) {
        console.log(e)
      }
    },
    agreeReplaceFileModal() {
      this.$bvModal.show('agree-replace-file-modal' + this.fileInfo.serverFileName);
    },
    async uploadFile() {
      try {
        this.isLoading = true;

        if (!this.uploadedFile) {
          return this.$snotify['error']('Soubor nenalezen');
        }
        const url = `/file/uploadFiles`;
        const formData = new FormData();
        formData.append('id', this.fileInfo._id);
        formData.append('fileName', this.uploadedFile.name);
        formData.append('file', this.uploadedFile);

        const { data } = await axios.post(url, formData, { responseType: "blob" });
        if (!Object.values(MIME_TYPES).includes(data?.type)) {
          return this.$snotify['error']('Problém se stahováním souboru');
        } else {
          this.$snotify['success']('Nový soubor nahrán!');
        }

        this.uploadedFile = {};
        this.$refs.fileInput.value = '';
        this.showEditBlock = false;
        this.hideModal();
        this.isLoading = false;
        this.$emit('updateFiles');
      } catch (e) {
        console.log(e)
      }
    },

    discardUpload() {
      this.uploadedFile = {};
      this.$refs.fileInput.value = '';
      this.showEditBlock = false;
      this.hideModal();
    },
    hideModal() {
      this.$bvModal.hide('agree-replace-file-modal' + this.fileInfo.serverFileName);
    }
  },
}
</script>

<style scoped>
.display-none {
  display: none;
}
</style>