<template>
  <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
    <div class="d-flex w-100 justify-content-center pt-0 pb-4">
      <div class="w-50 text-left fs-15px mr-3">
        <template>
          <div class="sectionName pt-0 mb-0">Obecné</div>
          <responsible-user
            :category="category.OBECNE"
            :categoryUser="usersKnihovna.obecne"
            :editCategory="editCategory"
            @changeUser="changeUser"
            @editKnihovnaUser="editCategory = category.OBECNE"
            @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.obecne" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('obecne', file.fileServerName)"
                 title="Stáhnout soubor"
                 v-if="file"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
<!--            <p v-if="files.obecne.length === 0">No files</p>-->
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Obchod</div>
          <responsible-user
            :category="category.OBCHOD"
            :categoryUser="usersKnihovna.obchod"
            :editCategory="editCategory"
            @changeUser="changeUser"
            @editKnihovnaUser="editCategory = category.OBCHOD"
            @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.obchod" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('obchod', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Realizace</div>
          <responsible-user
            :category="category.REALIZACE"
            :categoryUser="usersKnihovna.realizace"
            :editCategory="editCategory"
            @changeUser="changeUser"
            @editKnihovnaUser="editCategory = category.REALIZACE"
            @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.realizace" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('realizace', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
      </div>

      <div class="w-50 text-left fs-15px ml-3">
        <template>
          <div class="sectionName pt-0 mb-0">Projekce</div>
          <responsible-user
            :category="category.PROJEKCE"
            :categoryUser="usersKnihovna.projekce"
            :editCategory="editCategory"
            @changeUser="changeUser"
            @editKnihovnaUser="editCategory = category.PROJEKCE"
            @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.projekce" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('projekce', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Sklad</div>
          <responsible-user
            :category="category.SKLAD"
            :categoryUser="usersKnihovna.sklad"
            :editCategory="editCategory"
            @changeUser="changeUser"
            @editKnihovnaUser="editCategory = category.SKLAD"
            @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="file in files.sklad" :key="file.fileServerName">
              <a href="#"
                 @click="downloadFile('sklad', file.fileServerName)"
                 title="Stáhnout soubor"
              >
                <b-icon-download class="i-vert-02 mr-1" font-scale="1" />
                {{ file.fileName }}
              </a>
            </p>
          </b-card>
        </template>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import _ from "lodash";
import ResponsibleUser from "@/components/knihovna/ResponsibleUser.vue";
import saveAs from "save-as";
import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";
import { MIME_TYPES } from "@/constants/form.constants.js";

const knihovnaFiles = {
  obecne: [
    // {
    //   fileName: '',
    //   fileServerName: '',
    //   ext: '',
    // },
  ],
  obchod: [

  ],
  realizace: [

  ],
  projekce: [

  ],
  sklad: [

  ],
}

const CATEGORY = {
  OBECNE: 'obecne',
  OBCHOD: 'obchod',
  REALIZACE: 'realizace',
  PROJEKCE: 'projekce',
  SKLAD: 'sklad',
}

export default {
  components: {ResponsibleUser},
  mixins: [commonMixin],
  data() {
    return {
      category: CATEGORY,
      isLoading: false,
      files: knihovnaFiles,
      usersKnihovna: {
        obecne: null,
        obchod: null,
        realizace: null,
        projekce: null,
        sklad: null,
      },
      editCategory: null,
    };
  },
  computed: {  },
  methods: {
    async downloadFile(blockFiles, fileServerName) {
      const fileInfo = _.get(this.files, blockFiles).find(file => file.fileServerName === fileServerName);
      if (!fileInfo) {
        return this.$snotify['error']('Soubor nenalezen');
      }

      return this.downloadFileFromServer(fileInfo);
    },
    async downloadFileFromServer({fileServerName, fileName, ext}) {
      try {
        const url = `/file/knihovna/download`;
        const {data} = await axios.post(url, {fileName: fileServerName, ext}, {responseType: "blob"});
        if (!Object.values(MIME_TYPES).includes(data?.type)) {
          return this.$snotify['error']('Problém se stahováním souboru');
        }
        const fileNameWithExt = `${fileName}.${ext}`;
        saveAs(data, fileNameWithExt);
      } catch (e) {
        console.log('downloadFileFromServer', e)
      }
    },
    async changeUser() {
      this.editCategory = null;
      await this.getResponsibleUsers();
    },
    async getResponsibleUsers() {
      try {
        const url = `/knihovna/getResponsibleUsers`;
        const {data: {data}} = await axios.get(url) || {};
        this.usersKnihovna = {...this.usersKnihovna, ...data} || {};
      } catch (e) {
        console.log('ResponsibleUsers', e)
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getResponsibleUsers();
    this.isLoading = false;
  },
};
</script>

<style scoped>
.i-vert-02 {
  vertical-align: -0.2em;
}
.sectionName {
  font-size: 18px;
  margin-bottom: 5px;
  padding-top: 1.5rem;
  font-weight: bold;
  text-align: left;
  padding-left: 20px;
}
p {
  margin-bottom: 4px;
}
</style>
