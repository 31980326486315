<template>
  <div class="pl-3 pr-3" :class="[tabName !== 'seznamKontaktu' ? 'div-scroll' : '']">
    <tab-buttons :tabName="tabName" :tabs="tabsToDisplay" class="pt-4 pb-4 text-left" />
    <seznam-kontaktu-tab v-if="isCurrentTab('seznamKontaktu')" />
    <zakladni-dokumenty-tab v-if="isCurrentTab('zakladniDocumenty')" />
    <webove-stranky-tab v-if="isCurrentTab('weboveStranky')" />
    <smernice-tab v-if="isCurrentTab('smernice')" />
    <sablony-datatabulky-tab v-if="isCurrentTab('sablonyDatatabulky')" />
  </div>
</template>


<script>
import TabButtons from "@/components/TabButtons.vue";
import SeznamKontaktuTab from "@/views/Knihovna/SeznamKontaktuTab.vue";
import ZakladniDokumentyTab from "@/views/Knihovna/ZakladniDocumentyTab.vue";
import WeboveStrankyTab from "@/views/Knihovna/WeboveStrankyTab.vue";
import SmerniceTab from "@/views/Knihovna/SmerniceTab.vue";
import SablonyDatatabulkyTab from "@/views/Knihovna/SablonyDatatabulkyTab.vue";

const TABS = [
  {tabName: 'seznamKontaktu', link: '/knihovna/seznam-kontaktu', name: 'Seznam kontaktů'},
  {tabName: 'zakladniDocumenty', link: '/knihovna/zakladni-dokumenty', name: 'Základní dokumenty'},
  {tabName: 'weboveStranky', link: '/knihovna/webove-stranky', name: 'Dobré webové stránky'},
  {tabName: 'smernice', link: '/knihovna/smernice', name: 'Směrnice'},
  {tabName: 'sablonyDatatabulky', link: '/knihovna/sablony-datatabulky', name: 'Šablony a datatabulky'},
];

export default {
  components: {TabButtons, SeznamKontaktuTab, ZakladniDokumentyTab, WeboveStrankyTab, SmerniceTab, SablonyDatatabulkyTab},
  props: {
    tabName: {type: String, required: true},
  },
  computed: {  },
  data() {
    return {
      tabs: TABS,
      tabsToDisplay: []
    };
  },
  methods: {
    isCurrentTab(tabName) {
      return this.tabName === tabName;
    },
    async isTabHidden() {
      this.tabs.forEach(el => {
        if (el.tabName === 'sablonyDatatabulky') {
          el['hidden'] = !this.$store.getters.isSablonyDatatabulkyAllowed;
        }
      })
    },
    async filteredTabs() {
      this.tabsToDisplay = this.tabs.filter(tab => !tab.hidden)
      return this.tabsToDisplay
    }
  },
  async mounted() {
    await this.isTabHidden();
    await this.filteredTabs();
  }
};
</script>

<style scoped>

</style>
