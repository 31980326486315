<template>
  <div class="responsibleUser pt-0 d-flex pb-2">
    <span v-if="!canEdit" class="pl-1">{{ getKnihovnaUserName(category) }}</span>
    <template v-else>
      <ValidationObserver v-if="isInEdit()" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(saveUser)" class="d-flex align-items-center">
          <b-icon-pencil-square class="cursor-pointer fs-18px" variant="danger" @click="closeEditKnihovnaUser()" title="Close" />
          <ValidationProvider :rules="{ required: true, mustBeInList: { list: lists.usersNames }}" name="'Uživatel'" mode="lazy" v-slot="{ classes, errors }">
            <b-form-input
                list="activeUsersList"
                name="activeUsers"
                class="form-control form-control-sm w-400px ml-2 text-black"
                v-model.trim="formData.userName"
                :title="errors[0]"
                :state="getState(classes) && !validateWithDataFromList('userName', true, 'activeUsers')"
            />
          </ValidationProvider>
          <b-datalist
              id="activeUsersList"
              :options="lists.usersNames"
              autocomplete="off"
          />
          <b-icon-x-circle
              class="cursor-pointer filtered fs-16px ml-1"
              :disabled="!formData.userName"
              @click="clear()"
          />
          <b-button
              type="submit"
              variant="outline-success"
              class="ml-3 h-29px pt-2px"
          >
            Save
          </b-button>
        </b-form>
      </ValidationObserver>
      <template v-else>
        <b-icon-pencil-square class="cursor-pointer mt-1 fs-14px" variant="info" @click="editKnihovnaUser()" title="Edit" />
        <span class="pl-2"> {{ getKnihovnaUserName(category) }}</span>
      </template>
    </template>

  </div>
</template>

<script>

import _ from "lodash";
import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";

export default {
  components: {},
  mixins: [commonMixin],
  props: {
    category: { type: String },
    categoryUser: { type: String },
    editCategory: { type: String },
  },
  data() {
    return {
      formData: {
        userName: null,
      },
      lists: {
        activeUsers: [],
        usersNames: [],
      }
    };
  },
  computed: {
    canEdit() {
      return this.$store.getters.isZodpovednostVKnihovne;
    },
  },
  methods: {
    async getActiveUsers() {
      try {
        if (!this.$store.getters.isZodpovednostVKnihovne) {
          return;
        }

        const url = `/knihovna/getActiveUsers`;
        const {data: {data}} = await axios.get(url) || {};
        this.lists.activeUsers = data.users || [];
        this.lists.usersNames = data.usersNames || [];
      } catch (e) {
        console.log('getActiveUsers', e)
      }
    },
    async saveUser() {
      const userId = this.lists.activeUsers.find(user => user.name === this.formData.userName)?.id;
      const url = `/knihovna/saveActiveUserForCategory`;
      await axios.post(url, { category: this.category, id: userId });
      this.$emit('changeUser');
    },
    validateWithDataFromList(key, isInput, listName, isCanBeEmpty = false) {
      if (isCanBeEmpty && !this.formData[key]) return false;
      const isEqual = this.lists[listName || key].includes(_.get(this.formData, key));
      return isInput && !isEqual ? false : !isEqual;
    },
    editKnihovnaUser() {
      this.$emit('editKnihovnaUser');
      this.formData.userName = this.categoryUser;
    },
    closeEditKnihovnaUser() {
      this.$emit('closeEditKnihovnaUser');
      this.formData.userName = this.categoryUser;
    },
    isInEdit() {
      return this.editCategory === this.category;
    },
    getKnihovnaUserName() {
      return this.categoryUser ? `(zodpovídá ${this.categoryUser})` : '';
    },
    clear() {
      this.formData.userName = null;
    },
  },
  async mounted() {
    await this.getActiveUsers();
  },
};
</script>

<style scoped>
.responsibleUser {
  font-size: 14px;
  margin-bottom: 5px;
  padding-top: 1.5rem;
  text-align: left;
  padding-left: 20px;
}
</style>