<template>
  <b-overlay :show="isLoading" rounded="sm" class="mh-screen">
    <div class="d-flex w-100 justify-content-center pt-0 pb-4">
      <div class="w-33 text-left fs-15px">
        <template>
          <div class="sectionName pt-0 mb-0">Obecné</div>
          <responsible-user
              :category="category.OBECNE_WEB"
              :categoryUser="usersKnihovna.obecneWeb"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.OBECNE_WEB"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="webLink in webLinks.obecne" :key="webLink.link">
              <a :href="webLink.link"
                 target="_blank"
                 :title="'Jít do '+webLink.link"
              >
                {{ webLink.description }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Úřady</div>
          <responsible-user
              :category="category.URADY_WEB"
              :categoryUser="usersKnihovna.uradyWeb"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.URADY_WEB"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="webLink in webLinks.urady" :key="webLink.link">
              <a :href="webLink.link"
                 target="_blank"
                 :title="'Jít do '+webLink.link"
              >
                {{ webLink.description }}
              </a>
            </p>
          </b-card>
        </template>
      </div>

      <div class="w-33 text-left fs-15px ml-3">
        <template>
          <div class="sectionName pt-0 mb-0">Distributoři</div>
          <responsible-user
              :category="category.DISTRIBUTORI_WEB"
              :categoryUser="usersKnihovna.distributoriWeb"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.DISTRIBUTORI_WEB"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="webLink in webLinks.distributori" :key="webLink.link">
              <a :href="webLink.link"
                 target="_blank"
                 :title="'Jít do '+webLink.link"
              >
                {{ webLink.description }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Dotace</div>
          <responsible-user
              :category="category.DOTACE_WEB"
              :categoryUser="usersKnihovna.dotaceWeb"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.DOTACE_WEB"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="webLinks in webLinks.dotace" :key="webLinks.link">
              <a :href="webLinks.link"
                 target="_blank"
                 :title="'Jít do '+webLinks.link"
              >
                {{ webLinks.description }}
              </a>
            </p>
          </b-card>
        </template>
      </div>
      <div class="w-33 text-left fs-15px ml-3">
        <template>
          <div class="sectionName pt-0 mb-0">Požárně bezpečnostní řešení</div>
          <responsible-user
              :category="category.POZARNE_BEZPECNOSTNI_RESENI_WEB"
              :categoryUser="usersKnihovna.pozarneBezpecnostniReseniWeb"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.POZARNE_BEZPECNOSTNI_RESENI_WEB"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="webLink in webLinks.pozarneBezpecnostniReseni" :key="webLink.link">
              <a :href="webLink.link"
                 target="_blank"
                 :title="'Jít do '+webLink.link"
              >
                {{ webLink.description }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Obnovitelné zdroje energie</div>
          <responsible-user
              :category="category.OBNOVITELNE_ZDROJE_ENERGIE_WEB"
              :categoryUser="usersKnihovna.obnovitelneZdrojeEnergieWeb"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.OBNOVITELNE_ZDROJE_ENERGIE_WEB"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="webLink in webLinks.obnovitelneZdrojeEnergie" :key="webLink.link">
              <a :href="webLink.link"
                 target="_blank"
                 :title="'Jít do '+webLink.link"
              >
                {{ webLink.description }}
              </a>
            </p>
          </b-card>
        </template>
        <template>
          <div class="sectionName mb-0">Webináře</div>
          <responsible-user
              :category="category.WEBINARE_WEB"
              :categoryUser="usersKnihovna.webinareWeb"
              :editCategory="editCategory"
              @changeUser="changeUser"
              @editKnihovnaUser="editCategory = category.WEBINARE_WEB"
              @closeEditKnihovnaUser="editCategory = null"
          />
          <b-card>
            <p v-for="webLink in webLinks.webinare" :key="webLink.link">
              <a :href="webLink.link"
                 target="_blank"
                 :title="'Jít do '+webLink.link"
              >
                {{ webLink.description }}
              </a>
            </p>
          </b-card>
        </template>
      </div>
    </div>
  </b-overlay>
</template>

<script>

import ResponsibleUser from "@/components/knihovna/ResponsibleUser.vue";
import axios from "axios";

const CATEGORY = {
  OBECNE_WEB: 'obecneWeb',
  URADY_WEB: 'uradyWeb',
  DISTRIBUTORI_WEB: 'distributoriWeb',
  DOTACE_WEB: 'dotaceWeb',
  POZARNE_BEZPECNOSTNI_RESENI_WEB: 'pozarneBezpecnostniReseniWeb',
  OBNOVITELNE_ZDROJE_ENERGIE_WEB: 'obnovitelneZdrojeEnergieWeb',
  WEBINARE_WEB: 'webinareWeb',
}

const webLinks = {
  obecne: [
    {
      description: 'ARES – administrativní registr ekonomických subjektů',
      link: 'https://ares.gov.cz'
    },
    {
      description: 'ČÚZK – nahlížení do katastru nemovitostí',
      link: 'https://nahlizenidokn.cuzk.cz'
    },
    {
      description: 'Geoportál památkového ústavu',
      link: 'https://geoportal.npu.cz/webappbuilder/apps/100/'
    },
  ],
  urady: [
    {
      description: 'Energetický regulační úřad',
      link: 'https://eru.gov.cz'
    },
    {
      description: 'Český statistický úřad',
      link: 'https://www.czso.cz'
    },
  ],
  distributori: [
    {
      description: 'EG.D, a.s.',
      link: 'https://www.egd.cz'
    },
    {
      description: 'EG.D – mapa připojitelnosti FV výroben',
      link: 'https://pripojitelnost.egd.cz'
    },
    {
      description: 'EG.D – tisková konference k posilování distribuční sítě',
      link: 'https://www.youtube.com/watch?v=cDfp6ahTxJo'
    },
    {
      description: 'E.G.D – chytré měření neboli Smart Metering',
      link: 'https://www.youtube.com/watch?v=ox5M782GwJc'
    },
    {
      description: 'ČEZ, a. s.',
      link: 'https://www.cezdistribuce.cz'
    },
    {
      description: 'ČEZ – mapa připojitelnosti FV výroben',
      link: 'https://www.cezdistribuce.cz/cs/pro-vyrobce/volna-distribucni-kapacita-pro-pripojovani-vyroben/zobrazeni-mapy-pro-vyrobny-nn'
    },
    {
      description: 'ČEZ – žádost o připojení a montáž elektroměru na nové OM',
      link: 'https://www.youtube.com/watch?v=ZV_hxRgxxM0'
    },
    {
      description: 'ČEZ – jak funguje distribuční a přenosová soustava',
      link: 'https://www.youtube.com/watch?v=A1oLSBq3BAE'
    },
    {
      description: 'PREdistribuce, a. s.',
      link: 'https://www.predistribuce.cz/cs'
    },
  ],
  dotace: [
    {
      description: 'Nová zelená úsporám – dotace pro úsporné bydlení',
      link: 'https://novazelenausporam.cz'
    },
    {
      description: 'Seznam výrobků a technologií (SVT) k získaní podpory NZÚ',
      link: 'https://svt.sfzp.cz/'
    }
  ],
  pozarneBezpecnostniReseni: [
    {
      description: 'Hasičský záchranný sbor České republiky',
      link: 'https://www.hzscr.cz'
    }
  ],
  obnovitelneZdrojeEnergie: [
    {
      description: 'UKEN – Unie komunitní energetiky',
      link: 'https://www.uken.cz/clanky'
    },
    {
      description: 'Frank Bold – stavební právo, energetika a právo životního prostředí',
      link: 'https://www.fbadvokati.cz/cs/clanky/specializace/16'
    },
    {
      description: 'Evolty.cz – pomáhá lidem přejít k obnovitelným zdrojům energie',
      link: 'https://evolty.cz'
    },
    {
      description: 'TZB-info – obnovitelná energie',
      link: 'https://oze.tzb-info.cz'
    },
  ],
  webinare: [
    {
      description: 'Solární asociace – Webináře',
      link: 'https://www.solarniasociace.cz/webinare'
    }
  ]
}

export default {
  props: {

  },
  components: {ResponsibleUser},
  computed: {},
  data() {
    return {
      category: CATEGORY,
      isLoading: false,
      currentTab: 'weboveStranky',
      usersKnihovna: {
        obecneWeb: null,
        uradyWeb: null,
        distributoriWeb: null,
        dotaceWeb: null,
        pozarneBezpecnostniReseniWeb: null,
        obnovitelneZdrojeEnergieWeb: null,
        webinareWeb: null,
      },
      editCategory: null,
      webLinks,
    };
  },
  methods: {
    async changeUser() {
      this.editCategory = null;
      await this.getResponsibleUsers();
    },
    async getResponsibleUsers() {
      try {
        const url = `/knihovna/getResponsibleUsers`;
        const {data: {data}} = await axios.get(url) || {};
        this.usersKnihovna = {...this.usersKnihovna, ...data} || {};
      } catch (e) {
        console.log('ResponsibleUsers', e)
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getResponsibleUsers();
    this.isLoading = false;
  },
};

</script>

<style scoped>
.i-vert-02 {
  vertical-align: -0.2em;
}
.sectionName {
  font-size: 18px;
  margin-bottom: 5px;
  padding-top: 1.5rem;
  font-weight: bold;
  text-align: left;
  padding-left: 20px;
}
p {
  margin-bottom: 4px;
}
.w-33 {
  width: 33% !important;
}
</style>
