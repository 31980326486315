var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticClass:"mh-screen",attrs:{"show":_vm.isLoading,"rounded":"sm"}},[_c('b-table',{staticClass:"stickyTable border-bottom",attrs:{"sticky-header":"calc(100vh - 13rem)","bordered":"","responsive":"","hover":"","no-border-collapse":"","items":_vm.users,"fields":_vm.headers,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-direction":"desc"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(phone)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.phone))])]}},{key:"cell(Divize)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.Divize))])]}},{key:"cell(joinedName)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(item.joinedName))])]}},{key:"cell(position)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.position))])]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getEmailLink(item.email))}})]}},{key:"cell(note)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.note))])]}}])}),(!_vm.users.length)?_c('div',{staticClass:"pt-5"},[_c('h5',{staticClass:"p-5 fs-23px"},[_vm._v("Žádné výsledky vyhledávání")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }